const LabsText = () => {
    return ( <div className="hidden lg:block relative overflow-hidden" >
          <h6 className="whitespace-pre-wrap labs_text text-center labs_text  !text-[1.1vw] text-white">

███╗   ██╗ ██████╗ ██████╗ ██╗████████╗███████╗██╗  ██╗    ██╗      █████╗ ██████╗ ███████╗<br></br>
████╗  ██║██╔═══██╗██╔══██╗██║╚══██╔══╝██╔════╝╚██╗██╔╝    ██║     ██╔══██╗██╔══██╗██╔════╝<br></br>
██╔██╗ ██║██║   ██║██████╔╝██║   ██║   █████╗   ╚███╔╝     ██║     ███████║██████╔╝███████╗<br></br>
██║╚██╗██║██║   ██║██╔══██╗██║   ██║   ██╔══╝   ██╔██╗     ██║     ██╔══██║██╔══██╗╚════██║<br></br>
██║ ╚████║╚██████╔╝██████╔╝██║   ██║   ███████╗██╔╝ ██╗    ███████╗██║  ██║██████╔╝███████║<br></br>
╚═╝  ╚═══╝ ╚═════╝ ╚═════╝ ╚═╝   ╚═╝   ╚══════╝╚═╝  ╚═╝    ╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝
         
          </h6>  
    </div> );
}
 
export default LabsText;